document.addEventListener("turbo:load", estimateCost);
document.addEventListener("turbo:render", estimateCost);

export function estimateCost() {
  const form = document.getElementById('rental_form');
  if (!form) return;

  const pricePerDay = parseFloat(form.dataset.pricePerHour);
  const startDateField = form.querySelector('[name="rental[start_date]"]');
  const endDateField = form.querySelector('[name="rental[end_date]"]');
  const weeklyRateField = form.querySelector('[name="rental[weekly_rate]"]');
  const weeklyRate = parseFloat(weeklyRateField.value);
  const submitButton = form.querySelector('input[type="submit"]');
  const paymentMethodAdded = form.dataset.paymentMethodAdded === 'true';
  const costDisplay = document.getElementById('calculated_cost');
  const reservedDates = JSON.parse(startDateField.dataset.reservedDates || '[]');
  const priceForHalfDay = parseFloat(form.dataset.priceForHalfDay);
  const priceForTwoDays = parseFloat(form.dataset.priceTwoDays);
  const priceForOneWeek = parseFloat(form.dataset.priceOneWeek);
  const priceForTwoWeeks = parseFloat(form.dataset.priceTwoWeeks);
  const priceForOneMonth = parseFloat(form.dataset.priceOneMonth);
  const priceForTwoMonths = priceForOneMonth + (priceForOneMonth * 0.6);

  function validateForm() {
    const MS_PER_HOUR = 60 * 60 * 1000;
    const MS_PER_DAY = 24 * MS_PER_HOUR;
    const HOURS_IN_HALF_DAY = 12;
    const now = new Date();
    const startDateTime = new Date(startDateField.value);
    const endDateTime = new Date(endDateField.value);
    const durationInMS = endDateTime - startDateTime; 
    const durationInHours = durationInMS / MS_PER_HOUR;
    const durationInDays = Math.ceil(durationInMS / MS_PER_DAY);

    const datesAreValid = startDateTime >= now && endDateTime > startDateTime;
  
    if (!datesAreValid) {
      costDisplay.textContent = 'Dates invalides';
      if (submitButton) {
        submitButton.disabled = true;
      }
      return;
    }

    // Vérification si les dates sélectionnées se chevauchent avec des dates réservées
    if (isOverlapping(reservedDates, startDateTime, endDateTime)) {
      costDisplay.textContent = 'Les dates sélectionnées sont déjà réservées.';
      if (submitButton) {
        submitButton.disabled = true;
      }
      return;
  }
  
    // Cas où la location est moins de 3h
    if (durationInHours < 3) {
      costDisplay.textContent = 'La location doit être d\'au moins 3 heures';
      if (submitButton) {
        submitButton.disabled = true;
      }
      return;
    }

    if (durationInDays > 31) {
      costDisplay.textContent = 'La location ne peut pas dépasser un mois.';
      if (submitButton) {
        submitButton.disabled = true;
      }
      return;
    }
  
    // Tarification dégressive pour les locations de plus d'un jour
    let cost = 0;

    if (durationInDays >= 28 && durationInDays <= 31) {
      // Mois
      cost = priceForOneMonth;
    } else if (durationInDays >= 20 && durationInDays <= 22) {
      // 2 semaines
      cost = priceForTwoWeeks + priceForTwoWeeks/2;
    } else if (durationInDays >= 13 && durationInDays <= 15) {
      // 2 semaines
      cost = priceForTwoWeeks;
    } else if (durationInDays >= 6 && durationInDays <= 7) {
      // Semaine
      cost = priceForOneWeek;
    } else if (durationInHours > 24 && durationInDays <= 2) {
      // 2 jours
      cost = priceForTwoDays;
    } else if (durationInHours > 8 && durationInHours <= 24) {
      // 1 jour
      cost = pricePerDay;
    } else if (durationInHours >= 6 && durationInHours <= 8 ) {
      // Demi-journée
      cost = priceForHalfDay;
    } else {
      // Tarification pour les locations de moins de 24h
      if (durationInHours <= HOURS_IN_HALF_DAY) {
        cost = Math.ceil(durationInHours * pricePerDay / 24 * 1.4);
      } else if (durationInHours <= 24) {
        const extraHours = durationInHours - HOURS_IN_HALF_DAY;
        cost = Math.ceil(0.7 * pricePerDay + (extraHours * pricePerDay / 40));
      }
    }


    if (durationInDays > 2 && cost === 0) {
      let currentDayPrice = pricePerDay;

      if (pricePerDay < 9) {
        for (let i = 0; i < Math.min(6, durationInDays); i++) {
          cost += currentDayPrice; // Ajouter le prix du jour actuel
          if (weeklyRate !== undefined) {
            if (pricePerDay < 9) {
              currentDayPrice = parseFloat(Math.ceil(currentDayPrice * (1 - weeklyRate/100) * 10) / 10);
            } else {
              currentDayPrice = Math.ceil(currentDayPrice * (1 - weeklyRate/100));
            }
          }
        }
        // Pour les jours supplémentaires après le 7ème jour
        if ((durationInDays > 7 && durationInDays < 13) || (durationInDays > 22 && durationInDays < 28) || (durationInDays > 22 && durationInDays < 28)) {
          cost += (durationInDays - 7) * pricePerDay * 0.5;
        }
      } else {
        cost = Math.ceil(pricePerDay); // Prix journalier de base pour le premier jour
        let currentDayPrice = cost;
        for (let i = 1; i < Math.min(6, durationInDays); i++) {
          currentDayPrice = Math.ceil(currentDayPrice * (1 - weeklyRate/100)); // Réduction du weekly rate choisi par le propriétaire pour chaque jour supplémentaire
          cost += currentDayPrice;
        }
        // Pour les jours supplémentaires après le 7ème jour
        if ((durationInDays > 7 && durationInDays < 13) || (durationInDays > 15 && durationInDays < 20) || (durationInDays > 22 && durationInDays < 28)) {
          cost += Math.ceil((durationInDays - 7) * pricePerDay * 0.5);
        }
      }
    }

    costDisplay.textContent = 'Prix total estimé : ' + cost.toFixed(1) + '€';
    if (submitButton) {
      submitButton.disabled = false;
    }
    document.getElementById('total_cost').value = cost;
    if (submitButton) {
      submitButton.disabled = !paymentMethodAdded;
    }
  }

  startDateField.addEventListener('change', validateForm);
  endDateField.addEventListener('change', validateForm);
  weeklyRateField.addEventListener('input', validateForm);

  validateForm();
}


function isOverlapping(reservedDates, startDateTime, endDateTime) {
  return reservedDates.some(reservation => {
    return reservation.disabledHours.some(([startHour, endHour]) => {
      const reservationDate = new Date(reservation.date);
      const reservationStart = new Date(reservationDate.getFullYear(), reservationDate.getMonth(), reservationDate.getDate(), startHour);
      const reservationEnd = new Date(reservationDate.getFullYear(), reservationDate.getMonth(), reservationDate.getDate(), endHour);
      return startDateTime < reservationEnd && endDateTime > reservationStart;
    });
  });
}