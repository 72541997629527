import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="messages"
export default class extends Controller {
  static targets = ["container", "inputForm"];
  connect() {
    console.log(this.containerTarget);
    this.resetScroll();

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "childList") {
          this.resetScroll();
        }
      });
    });

    this.observer.observe(this.containerTarget, {
      childList: true, // Observe l'ajout ou le retrait d'enfants
    });
  }

  disconnect() {
    // Assurez-vous de déconnecter l'observateur quand le contrôleur est déconnecté
    this.observer.disconnect();
  }

  resetScroll() {
    const scrollHeight = this.containerTarget.scrollHeight;

    this.containerTarget.scrollTo(0, scrollHeight);
  }

  resetForm() {
    this.inputFormTarget.reset();
  }
}
